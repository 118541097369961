
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: $primary;
  padding-top: 8px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.content {
  @include t2;

  color: $white;
  width: 100%;
  max-width: $xl;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  @include breakpoint($md-and-sm) {
    padding: 0 20px;
  }
}

.action {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $white-hover;
  }
}

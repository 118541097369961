
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.phoneInput {
  display: flex;

  .telephoneInput {
    flex-grow: 1;
  }
}

.errorText {
  @include t7;

  color: $error;
}

.telephoneCodeWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-top: 1px solid $border;
  border-left: 1px solid $border;
  border-bottom: 1px solid $border;
  background-color: $black-5;

  span {
    @include p3;

    color: $black-60;
  }
}

.telephoneCodeWrap.focused {
  border-top: 1px solid $input-border-focus;
  border-left: 1px solid $input-border-focus;
  border-bottom: 1px solid $input-border-focus;
}

.telephoneCodeWrap.error {
  border-top: 1px solid $error-border;
  border-left: 1px solid $error-border;
  border-bottom: 1px solid $error-border;
}

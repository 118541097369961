
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  color: $black;
  border: 1px solid $border;
  padding: 10px 16px;

  @include p3;

  font-family: inherit;
  height: 40px;
  width: 100%;

  @include gap(5px);

  &:focus {
    border: 1px solid $input-border-focus;
  }

  &__error {
    border: 1px solid $error;

    &:focus {
      border: 1px solid $error;
    }
  }
}

.input[type='number']::-webkit-outer-spin-button,
.input[type='number']::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.input[type='number'] {
  appearance: textfield;
}

.input[type='number']:hover,
.input[type='number']:focus {
  appearance: none;
}

.errorText {
  @include t7;
}

.input[disabled] {
  color: $black-40;
}

.input + .errorText {
  color: $error;
}

.iconWrapper {
  cursor: pointer;
  position: absolute;
  right: 115px;
  top: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include breakpoint($sm) {
    right: 60px;
    top: 55px;
  }

  &_modal {
    cursor: pointer;
    position: absolute;
    right: 155px;
    top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint($sm) {
      right: 80px;
      top: 31px;
    }
  }

  &_back {
    cursor: pointer;
    position: absolute;
    left: 60px;
    top: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint($sm) {
      left: 40px;
      top: 29px;
    }
  }
}

.offset {
  padding-left: 58px;
  padding-right: 52px;
}


      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.button {
  @include b3;

  color: $black;
  display: block;
  width: 100%;
  padding: 0 45px;
}

.wrapper {
  position: relative;
  margin-bottom: 20px;
}

.icon {
  position: absolute;
  width: 45px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $button-label-bg;
}

.white {
  background: rgb(255 255 255 / 10%);

  svg path {
    fill: $white;
  }
}

.labelIcon {

  svg path {
    fill: $button-label-icon;
  }
}

.esia {
  border: 1px solid #0d4cd3;
  background-color: $white;
}

.withoutGap {
  margin-bottom: 0;
}

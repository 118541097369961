
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.close {
  cursor: pointer;
  position: absolute;
  top: -45px;
  right: 0;

  @include breakpoint($sm) {
    display: none;
  }

  &_mobile {
    display: none;
    margin-top: 21px;

    @include breakpoint($sm) {
      top: -5px;
      right: 15px;
      display: block;
    }
  }
}

.modalWrapper {
  position: relative;
  padding: 60px 40px;
  width: 450px;
  height: 266px;

  @include breakpoint($sm) {
    width: 100%;
    height: auto;
  }
}

.wrapper {
  position: relative;
  width: auto;
  max-width: 580px;

  @include breakpoint($sm) {
    margin: 0 auto;
    padding: 16px;
    width: 100%;
  }
}

.wrapperInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  @include h3;

  color: $black;
  margin-bottom: 40px;
  text-align: center;
}

.actions {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px;

  @include breakpoint($sm) {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
}


      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.button {
  position: fixed;
  right: 20px;
  bottom: 110px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $live;
  z-index: 2;
  transform: rotate(180deg);

  @include breakpoint($xl) {
    left: calc(50% + 640px);
  }

  @include breakpoint($sm) {
    bottom: 150px;
  }

  &:hover {
    background-color: $live-hover;
  }

  svg {
    overflow: visible;
    transform: rotate(180deg);

    path {
      fill: $white;
    }
  }
}


      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.header {
  @include h2;

  text-align: center;
  margin-bottom: 30px;
}

.label {
  @include t4;

  color: $black-60;
  display: block;
  margin-bottom: 12px;
}

.input_wrapper {
  margin-bottom: 25px;
}

.backArrow {
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
}

.captcha {
  margin-top: 30px;
}

.text_error {
  @include t7;

  color: $error;
}

.submit {
  margin-top: 30px;
}

.register {
  margin-top: 20px;
}


      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.suggestionsItem {
  display: flex;
  align-items: center;

  @include p3;

  min-height: 40px;
  cursor: pointer;

  @include breakpoint($sm) {
    height: initial;
  }

  a {
    color: $black;
  }

  &:hover {
    background-color: $white-hover;
  }
}


      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.radio {
  cursor: pointer;
  display: flex;

  .radio_circle {
    background-color: $white;
    border: 1px solid $border;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    height: 16px;
    margin-right: 12px;
    vertical-align: middle;
    width: 16px;
    transition: border-color 0.3s ease;
  }

  span {
    @include t4;

    margin-right: 20px;
  }

  .radio_button {
    display: none;

    &:checked + .radio_circle {
      background-color: $white;
      border: 4px solid $primary;
    }
  }
}


      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.header {
  @include h2;

  text-align: center;
  margin-bottom: 40px;
}

.text {
  @include t1;

  text-align: left;
  vertical-align: top;

  &_error {
    @include t7;

    color: $error-border;
  }
}

.textBlock {
  padding-bottom: 40px;
}

.lost_password {
  @include b3;

  color: $primary;
}

.lost_password_block {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  @include b3;

  color: $black;
  display: block;
  width: 100%;

  &.secondary {
    background-color: #fff;
    border: 1px solid $primary;
    color: $primary;
  }

  &.email_bel {
    color: $white;
  }

  margin-bottom: 20px;
}

.delimeter {
  @include b3;

  color: $black-60;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  background-color: $white;

  &:before {
    content: '';
    display: block;
    width: 230px;
    height: 1px;
    background: $border;
    right: 0;
    top: 50%;
    position: absolute;
  }

  &:after {
    content: '';
    display: block;
    width: 230px;
    height: 1px;
    background: $border;
    left: 0;
    top: 50%;
    position: absolute;
  }

  // ширина линий вычисляется в зависимости от контента
  // При изменении текста потребуется поменять значения ширины

  &_or {

    &:before { width: calc((100% - 50px) / 2); }

    &:after { width: calc((100% - 50px) / 2); }
  }

  &_enter {

    &:before { width: calc((100% - 150px) / 2); }

    &:after { width: calc((100% - 150px) / 2); }
  }
}

.agreement {
  @include t4;

  color: $black-50;
  margin-top: 20px;
  text-align: center;
}

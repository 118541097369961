
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.button {
  @include b3;

  height: 40px;
  width: 140px;
  text-transform: uppercase;
  color: $button-text-action-default;
  background: $button-bg-default;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: $button-bg-hover;
  }

  &[disabled] {
    cursor: auto;
    background: $button-bg-disabled;
    color: $white;
  }

  @include breakpoint($sm) {
    margin: 0 auto;
  }
}


      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
@import 'SearchModal.module.bel';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  padding: 0 40px 32px;

  @include breakpoint($sm) {
    margin: 0 auto;
    padding: 16px;
    width: 100%;
  }
}

.button {
  font-weight: 400;
  width: 90px;

  @include breakpoint($sm) {
    min-width: 90px;
  }
}

.iconButton {
  position: absolute;
  top: 25px;
  right: 40px;
  height: 20px;
  width: 20px;

  svg {

    path {
      fill: $primary;
    }
  }
}

.input_wrapper {
  width: 578px;
  margin-bottom: 25px;
  padding-right: 10px;

  @include breakpoint($sm) {
    padding-right: 0;
  }
}

.offset {
  padding-left: 58px;
}

.suggestionsBlock {
  position: absolute;
  cursor: pointer;
  background-color: $white;
  filter: drop-shadow(0 11px 25px rgb(0 0 0 / 8%));
  z-index: 2;
  margin-top: -5px;

  @include breakpoint($xl-and-md) {
    left: 40px;
    right: 140px;
  }

  @include breakpoint($sm) {
    background-color: initial;
  }

  a {
    width: 100%;
    padding: 10px 20px 10px 58px;
  }
}

.totalItemClassname {
  display: flex;
  align-items: center;

  @include t3;

  width: 100%;
  padding: 10px 20px 10px 58px;
  min-height: 40px;
  cursor: pointer;
  color: $primary;

  &:hover {
    background-color: $white-hover;
  }
}

.hideIcon {

  span {
    display: none;
  }
}

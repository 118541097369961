
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.close {
  cursor: pointer;
  position: absolute;
  top: -45px;
  right: 0;

  @include breakpoint($sm) {
    display: none;
  }

  &_mobile {
    display: none;
    margin-top: 21px;

    @include breakpoint($sm) {
      top: -5px;
      right: 15px;
      display: block;
    }
  }
}

.wrapper {
  position: relative;
  width: auto;
  max-width: 580px;

  @include breakpoint($sm) {
    margin: 0 auto;
    padding: 0;
    width: 100%;

    svg {
      display: none;
    }
  }
}

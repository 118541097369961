
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
@import 'RestorePasswordModal.bel.module';

.close {
  cursor: pointer;
  position: absolute;
  top: -45px;
  right: 0;

  @include breakpoint($sm) {
    display: none;
  }

  &_mobile {
    display: none;
    margin-top: 21px;

    @include breakpoint($sm) {
      top: -5px;
      right: 15px;
      display: block;
    }
  }
}

.wrapper {
  position: relative;
  padding: 60px 40px;
  width: 587px;

  @include breakpoint($sm) {
    margin: 0 auto;
    padding: 60px 16px;
    width: 100%;
  }
}

.header {
  text-align: center;
  color: $black;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 10px;

  @include breakpoint($sm) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 5px;
  }
}

.text {
  text-align: center;
  vertical-align: top;
  color: $black;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;

  &_error {
    text-align: left;
    color: $error-border;
    font-size: 12px;
    line-height: 16px;
  }
}

.textBlock {
  padding-bottom: 30px;
}

.label {
  color: $black-60;
  display: block;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 12px;
  font-weight: 400;

  @include breakpoint($sm) {
    font-size: 12px;
    line-height: 16px;
  }
}

.input_wrapper {
  margin-bottom: 25px;
}

.button {
  color: $black;
  display: block;
  margin-bottom: 20px;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;

  &.secondary {
    background-color: #fff;
    border: 1px solid $primary;
    color: $primary;
  }

  &.disabled {
    background-color: $button-bg-disabled;
    color: $white;
  }
}

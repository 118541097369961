@import '~breakpoint-sass';

$main-font-family: 'Roboto', -apple-system, blinkmacsystemfont, segoe ui, roboto, oxygen,
  ubuntu, cantarell, fira sans, droid sans, helvetica neue, sans-serif;
$font-variant-numeric: initial;

// COLORS

$layout-bg: #fff;
$black: #000;
$white: #fff;
$white-hover: #fafafa;
$border: #e5e5e5;
$primary: #113a76;
$primary-hover: #0d2953;
$primary-disabled: #a2a3a9;
$secondary: #3fa9f5;
$secondary-hover: #1e78b9;
$secondary-text: #fff;
$tertiary: #90db4f;
$tertiary-hover: #73c42c;
$tertiary-text: #000;
$live: #ff6a5f;
$live-hover: #e51000;
$scroll-up-button-default: rgba($black, 0.6);
$scroll-up-button-hover: rgba($black, 1);
$rating-star-filled: #f3df76;
$rating-star-empty: rgba($white, 0.2);
$social-media-icon-color: #6fbbe9;
$social-media-icon-bg: rgba(#fff, 0);
$subscribe-form-bg: #fff;
$subscribe-form-border: #e5e5e5;
$error: #ff4133;
$error-border: $error;
$content-link: #1a5ab9;
$content-widget-bg: #f2f2f2;
$placeholder-bg: #f2f6f9;
$input-border-focus: #7bc8ea;
$notification-default-bg: #f8fcfe;
$notification-success-bg: #f7fbfb;
$notification-warning-bg: #fefdf6;
$notification-error-bg: #fff6f6;
$pushkin-pink: #ff0090;
$pushkin-bar: $primary;
$pushkin-card-bg: #90db4f;
$pushkin-card-fill: #000;
$header-button-search-bg-default: #3fa9f5;
$header-button-search-bg-hover: #1e78b9;
$header-button-search-icon-default: #fff;
$header-button-search-icon-hover: #fff;
$header-button-profile-bg-default: #90db4f;
$header-button-profile-bg-hover: #73c42c;
$header-button-profile-icon-default: #000;
$header-button-profile-icon-hover: #000;
$header-block-bg: #113a76;
$header-block-text: #fff;
$header-block-info-button: rgba(#fff, 0.6);
$header-block-hint-bg: rgba(#fff, 0.1);
$header-block-arrows-adaptive: #fff;
$button-bg-default: #7bc8ea;
$button-bg-hover: #3fa9f5;
$button-bg-disabled: #acb5b9;
$button-text-action-default: #000;
$button-text-price-default: rgb(0 0 0 / 60%);
$button-icon-default: rgb(0 0 0 / 60%);
$button-divider-default: rgb(0 0 0 / 30%);
$button-label-bg: rgb(255 255 255 / 10%);
$button-label-icon: #fff;
$button-vk-bg-default: #07f;
$button-vk-bg-hover: #0060cd;
$card-color-bg-default: #113a76;
$card-color-bg-hover: #0d2953;
$card-color-text-main: #fff;
$card-color-text-secondary: rgb(255 255 255 / 60%);
$card-color-badge-bg: rgba($primary, 0.7);
$card-main-border: #e5e5e5;
$card-main-bg-default: #fff;
$card-main-bg-hover: #fafafa;
$overview-card-border: #e5e5e5;
$overview-card-bg-default: #fff;
$overview-card-bg-hover: #fafafa;
$overview-card-text: #000;
$overview-card-badge-bg: rgba($black, 0.05);
$overview-button-text-default: rgba($white, 1);
$overview-button-bg-default: rgba($black, 1);
$overview-button-hover: rgba($black, 1);
$hotel-card-border: #e5e5e5;
$hotel-card-bg-default: #fff;
$hotel-card-bg-hover: #fafafa;
$tickets-bundle-card-border: #e5e5e5;
$tickets-bundle-card-bg-default: #fff;
$tickets-bundle-card-bg-hover: #fafafa;
$stream-card-border: #e5e5e5;
$stream-card-bg-default: #fff;
$stream-card-bg-hover: #fafafa;
$region-videos-card-border: #e5e5e5;
$region-videos-card-bg-default: #fff;
$region-videos-card-bg-hover: #fafafa;
$footer-bg: #113a76;
$footer-text-main: #fff;
$footer-text-secondary: rgb(255 255 255 / 60%);
$footer-link-text-default: rgb(255 255 255 / 80%);
$footer-link-text-hover: #fff;
$footer-border: rgb(255 255 255 / 20%);
$header-transparent-item-default: $white;
$header-transparent-item-hover: $primary;
$time-tag-bg-active: $white;
$time-tag-border-active: $black;
$excursion-main-button-text-default: $white;
$excursion-main-button-bg-default: #009fe3;
$excursion-main-button-hover: #009fe3;
$excursion-main-card-bg-default: #009fe3;
$excursion-main-card-bg-hover: #0090ce;
$excursion-main-card-text: $white;
$excursion-main-card-badge-bg: rgba($white, 0.2);
$excursion-card-bg-default: $white;
$excursion-card-bg-hover: $white-hover;
$excursion-card-border: $border;
$tour-main-card-bg-default: $white;
$tour-main-card-bg-hover: $white-hover;
$tour-main-card-border: $border;
$tour-main-card-text: $black;
$tour-main-badge-bg: rgba($black, 0.05);
$tour-main-button-text-default: $white;
$tour-main-button-bg-default: $black;
$tour-main-button-hover: $black;
$news-main-card-bg-default: $white;
$news-main-card-bg-hover: $white-hover;
$news-main-card-border: $border;
$news-main-card-text: $black;
$news-main-badge-bg: rgba($black, 0.05);
$news-main-button-text-default: $white;
$news-main-button-bg-default: $black;
$news-main-button-hover: $black;

// HEIGHT CONSTANTS
$event-pay-button-height: 70px;
$event-video-xl: 500px;
$event-video-md: 335px;

// BREAKPOINTS:
// XL = desktop, MD = tablet, SM = mobile
$xl: 1200px;
$md: 1199px 768px;
$sm: 767px 0;
$md-and-sm: 1199px 0;
$xl-and-md: 768px;

// WIDTH CONSTANTS
$event-pay-button-width: 335px;
$event-width-wrapper: 1000px;
$event-width-content: 800px;
$max-width-image: 600px;

// OTHER CONSTANTS
$modal-z-index: 101;
$content-bold-weight: 600;
$card-bg-transition: background-color 0.3s ease;
$timetable-date-padding-top: 6px;

@mixin gap($space) {

  &:not(:last-child) {
    margin-bottom: $space;
  }
}

@mixin scrollbar() {

  &::-webkit-scrollbar-track {
    background-color: $black-5;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 0;
  }

  scrollbar-color: $primary $black-5;
  scrollbar-width: 10px;
}

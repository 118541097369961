
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.notifications {
  margin-right: 40px;
  margin-bottom: 40px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 450px;
  overflow: hidden;
  padding: 5px;
  position: fixed;
  pointer-events: none;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 102;

  @include breakpoint($sm) {
    max-width: 335px;
  }
}

.notification {
  align-items: center;
  background-color: $notification-success-bg;
  display: flex;
  margin: 5px;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;
  box-shadow: 0 1px 6px rgb(0 0 0 / 16%);

  &_closing {
    animation: notification-closing 0.3s ease-out;
  }

  &_error {
    background-color: $notification-error-bg;
  }

  &_warning {
    background-color: $notification-warning-bg;
  }

  &_default {
    background-color: $notification-default-bg;
  }
}

.content {
  max-width: 376px;

  @include t2;

  padding: 20px 20px 20px 14px;
  width: 100%;

  @include breakpoint($sm) {
    max-width: 261px;
  }
}

@keyframes notification-showing {

  0% {
    opacity: 0;
    margin-left: 100%;
  }
}

@keyframes notification-closing {

  100% {
    opacity: 0;
    margin-left: 100%;
  }
}

.image {
  margin-left: 20px;
}

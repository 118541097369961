
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.button {
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
    transition: all 0.2s ease;
  }
}

.desktopLink {
  display: flex;
  height: 100%;
}

@media (hover: hover) {

  .button:hover {

    .hidden {
      display: block;
      width: auto;
      z-index: 20;
    }

    .title {
      color: $primary !important;

      svg {
        transform: rotate(180deg);

        path {
          fill: $primary;
        }
      }
    }
  }
}

.hidden {
  display: none;
}

.open {
  width: auto;
  z-index: 20;
}

.mobileDropdown.button {
  display: flex;
  flex-direction: column;
  align-items: start;
  user-select: none;
}

.mobileDropdownList.dropdownList {
  position: relative;
  background-color: $white;
  box-shadow: unset;
  padding: unset;
  margin-bottom: 20px;
}

.dropdownList {
  position: absolute;
  padding: 20px 18px;
  font-weight: 400;
  background-color: $white;
  box-shadow: 0 2px 25px rgb(0 0 0 / 15%);

  @include breakpoint($xl) {
    left: 0;
    top: 70px;
  }

  @include breakpoint($md-and-sm) {
    left: 20px;
    top: 20px;
  }
}

.listItem {
  display: list-item;
  white-space: nowrap;
  padding: 10px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  cursor: pointer;

  @include menuHeader;

  @include breakpoint($md) {
    @include t3;
  }

  @include breakpoint($sm) {
    @include t4;
  }

  &:hover {
    color: $primary;
    text-decoration: none;
  }
}

.title {
  display: flex;
  height: 100%;
  align-items: center;
  user-select: none;
  transition: color 0.2s ease;
}

.text {
  display: flex;
  align-items: center;
  user-select: none;

  svg path {
    fill: $black;
  }
}

.title_open {
  color: $primary !important;

  svg {
    transform: rotate(180deg);

    path {
      fill: $primary;
    }
  }
}


      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  display: flex;
  flex-direction: column;
  background-color: $footer-bg;
  width: 100%;

  a {
    color: $footer-link-text-default;
  }

  a:hover {
    text-decoration: none;
    color: $footer-link-text-hover;
  }

  @include breakpoint($xl) {
    margin: 0 auto;
  }
}

.logoAndInfo {
  display: flex;
  flex-flow: column nowrap;
  gap: 30px;
  color: $footer-text-main;

  @include p3;

  @include breakpoint($md) {
    order: 2;
    gap: 25px;
  }

  @include breakpoint($sm) {
    order: 2;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  @media (max-width: 374px) {
    flex-flow: column nowrap;
  }
}

.logo_wrap {
  display: flex;
}

.logo {
  height: 50px;
}

.titleText {
  white-space: pre;
}

.helpers {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;

  @include breakpoint($sm) {
    width: 190px;
  }
}

.row {
  max-width: calc($xl + 120px);
  width: 100%;
  margin: 0 auto;
  padding: 50px 60px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @include breakpoint($md) {
    flex-flow: row wrap;
    gap: 40px;
    padding: 30px 60px 50px;
  }

  @include breakpoint($sm) {
    flex-flow: row wrap;
    gap: 40px;
    padding: 30px 20px 50px;
  }
}

.menu {
  position: relative;
  flex: 1;
  margin-top: 20px;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(6, auto);
  grid-auto-flow: column;
  justify-content: space-evenly;
  row-gap: 10px;
  color: $footer-text-main;

  @include p3;

  @include breakpoint($md) {
    order: 1;
    width: 100%;
    min-width: 100%;
    grid-template-columns: auto auto auto 165px;
    grid-template-rows: repeat(3, auto);
    padding-bottom: 50px;
    margin-bottom: 10px;
    justify-content: space-between;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -60px;
      width: calc(100% + 120px);
      border-bottom: 1px solid $footer-border;
    }
  }

  @include breakpoint($sm) {
    order: 1;
    width: 100%;
    min-width: 100%;
    grid-template-columns: auto 190px;
    grid-template-rows: repeat(6, auto);
    padding-bottom: 50px;
    margin-bottom: 10px;
    justify-content: space-between;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -20px;
      width: calc(100% + 40px);
      border-bottom: 1px solid $footer-border;
    }
  }

  @media (max-width: 374px) {
    grid-template-columns: auto auto;
  }
}

.menuLink {

  & + & {
    // margin-top: 10px;
  }
}

.support {
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  color: $footer-text-main;
  gap: 20px;

  @include p3;

  @include breakpoint($md) {
    width: 165px;
    margin-top: 0;
    order: 3;
    justify-content: space-between;
  }

  @include breakpoint($sm) {
    width: 100%;
    margin-top: 0;
    order: 3;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  @media (max-width: 374px) {
    flex-flow: column nowrap;
  }
}

.info {
  @include p3;

  @include breakpoint($sm) {
    order: 2;
    width: 190px;
  }

  &__title {
    color: $footer-text-secondary;
  }

  &__phone {
    margin-top: 1px;

    @include number;

    color: $footer-text-main;
  }

  &__callTime {
    @include t4;

    color: $footer-text-secondary;
  }

  &__mail {
    margin-top: 11px;
    color: $footer-text-main;
  }
}

.writeUs {
  margin-top: 10px;
}

.faq {
  margin-top: 10px;
}

.pushkincard {
  margin-top: 10px;
}

.login {
  margin-top: 20px;
}

.socials {
  display: flex;
  gap: 15px;

  @include breakpoint($sm) {
    order: 1;
    gap: 10px;
  }
}

.socialIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $footer-border;
  border-radius: 50%;
  width: 35px;
  height: 35px;

  svg path {
    fill: $footer-text-main;
  }

  &:hover {
    background-color: $footer-link-text-hover;
    cursor: pointer;

    svg path {
      fill: $footer-bg;
    }
  }
}

.copyright {
  border-top: 1px solid $footer-border;
  color: $footer-text-main;

  &__in {
    max-width: calc($xl + 120px);
    width: 100%;
    margin: 0 auto;
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    text-align: center;

    @include t4;

    color: $footer-text-main;

    @include breakpoint($md) {
      flex-flow: column nowrap;
      justify-content: center;
    }

    @include breakpoint($sm) {
      flex-flow: column nowrap;
      justify-content: center;
    }
  }

  &__text {
    @include breakpoint($md) {
      order: 2;
    }

    @include breakpoint($sm) {
      order: 2;
    }
  }

  &__svg {
    opacity: 0.5;
  }
}

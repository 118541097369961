
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.overlay {
  position: fixed;
  padding: 0 40px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  background-color: rgba($black, 0.4);
  z-index: $modal-z-index;
  overflow: auto;

  @include breakpoint($sm) {
    padding: 0;
  }
}

.modal {
  margin: 31px 0 auto;
  border-radius: 0;
  outline: none;

  * {

    &::-webkit-scrollbar {
      padding-right: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black;
      border-radius: 79px;
    }
  }

  &__backgroundColor {
    background: $white;
    margin: 80px auto auto;
  }

  @include breakpoint($sm) {
    background: $white;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
    overflow: auto;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  top: -45px;
  right: 0;

  @include breakpoint($sm) {
    display: none;
  }

  &_mobile {
    display: none;
    margin-top: 21px;

    @include breakpoint($sm) {
      top: -5px;
      right: 15px;
      display: block;
    }
  }
}

.wrapper {
  position: relative;
  padding: 0 40px 32px;
  width: 587px;

  @include breakpoint($sm) {
    margin: 0 auto;
    padding: 16px;
    width: 100%;
  }
}


      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
@import 'writeToUsForm.bel.module';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid $border;
  background-color: $white;
  padding-left: 40px;

  @include breakpoint($md) {
    padding: 60px;
  }

  @include breakpoint($sm) {
    padding: 40px;
  }

  @include breakpoint($xl) {
    height: 664px;
  }

  .input {
    display: unset;
  }

  .area {
    height: 200px;
  }
}

.wrapperInModal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;

  .form {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }

  @include breakpoint($sm) {
    margin: 0;
  }
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-top: 60px;

  @include breakpoint($md-and-sm) {
    margin-top: initial;
    max-width: initial;
    width: 100%;
  }

  @include breakpoint($sm) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.title {
  @include h2;

  margin-bottom: 4px;
}

.subTitle {
  @include t1;

  margin-bottom: 20px;

  @include breakpoint($sm) {
    @include p3;
  }
}

.subTitleModal {
  @include t1;

  margin-bottom: 30px;

  @include breakpoint($sm) {
    @include p3;
  }
}

.textLabel {
  @include t4;

  display: block;
  opacity: 0.6;
  margin-bottom: 10px;
}

.emailLabel {
  @include t4;

  display: block;
  opacity: 0.6;
  margin-bottom: 10px;
  margin-top: 18px;

  @include breakpoint($sm) {
    margin-top: 8px;
  }

  &_modal {
    @include breakpoint($sm) {
      margin-top: 18px;
    }
  }
}

.wrapperInner {
  display: flex;
  flex-direction: column;
}

.checkboxWrapper {
  margin-bottom: 39px;

  @include breakpoint($sm) {
    @include gap(40px);
  }

  &__output {
    margin-top: 10px;
    margin-bottom: 39px;
  }
}

.agreementText {
  @include t4;

  opacity: 0.6;
}

.inputRow {
  display: flex;
  flex-direction: row;
  width: 100%;

  @include gap(12px);

  textarea {
    width: 100%;
  }
}

.subscribed {
  @include t1;

  font-weight: 500;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint($md-and-sm) {
    display: none;
  }

  img {
    vertical-align: bottom;
  }
}

.desktopButton {
  @include breakpoint($sm) {
    display: none;
  }
}

.mobileButton {
  @include breakpoint($xl-and-md) {
    display: none;
  }
}

.button {
  @include b3;

  height: 40px;
  width: 100%;
  text-transform: uppercase;
  color: $button-text-action-default;
  background: $button-bg-default;
  cursor: pointer;

  &:hover {
    background: $button-bg-hover;
  }

  &[disabled] {
    cursor: auto;
    background: $button-bg-disabled;
    color: $white;
  }
}


      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
@import 'ModalLogo.bel.module';

.logoWrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  @include breakpoint($sm) {
    margin-bottom: 38px;
  }
}

.logo {
  height: 185px;
}


      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.header {
  @include h2;

  text-align: center;
  margin-bottom: 10px;
}

.description {
  @include t1;

  color: $black;
  text-align: center;
  margin-bottom: 30px;
}

.counter {
  @include p3;

  color: $black-40;
  margin-top: 30px;
  margin-bottom: 20px;
}

.resend {
  @include b3;

  color: $primary;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 20px;
}
